<template>
    <div class="homes-standard">
        <div class="homes-standard-cover cover container-fluid px-0 mt-5">
            <div class="container">
                <div class="row mx-2">
                    <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                        <h4 class="text-white mx-5 mt-5 mb-4">Стандартно</h4>
                        <p class="text-white mx-5 my-4">
                            След години опит сме разработили специални предложения за основно почистване. Пакетът Стандарт е правилният избор за освежаване на дома. Препоръчваме услугата за сезонни почиствания.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-3">
            <h3>Стандартно почистване</h3>
            <p>
                Пакетът Стандартно почистване е разработен като резултат от дългогодишния ни опит с клиенти и анализ на най-често желаните от тях услуги. Той обхваща голям диапазон от дейности, помещения и мебели в дома Ви.<br />
				<br />
				Всекидневната, спалнята, кухнята - това са най-обитаваните зони в дома и там се натрупват най-много замърсявания. Именно заради това обръщаме специално внимание на всеки елемент в тези помещения. В зависимост от покритието на повърхностите се използват подходящи почистващи препарати.<br />
				<br />
				При килими, мокети и тапицерия се използват машини тип екстрактор, които премахват замърсяванията в дълбочина. За твърдите подови настилки се използват специални подопочистващи машини, работещи с четка на високи обороти. Използваните препарати се съобразяват с покритието на настилката.
            </p>
            <div class="bagdes container my-5">
                <div class="row">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="serviceActive = true, service2Active = false, service3Active = false, service4Active = false, service5Active = false, service6Active = false">
                            <span class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512" width="75" height="75">
                                    <title>Living Room</title>
                                    <path d="M473,331.916V273.648A39.71,39.71,0,0,0,433.328,234H376V170h44.009a6.289,6.289,0,0,0,5.747-8.02L404.831,92.248A5.976,5.976,0,0,0,399.084,88H340.916a5.932,5.932,0,0,0-5.747,4.2L314.244,161.92a6.372,6.372,0,0,0,.934,5.483,6.176,6.176,0,0,0,4.813,2.6H364v64H88.675A39.713,39.713,0,0,0,49,273.648v58.268a39.417,39.417,0,0,0-37,39.556V463.1a5.915,5.915,0,0,0,6,5.9H35v30a6.246,6.246,0,0,0,6.28,6h67.867A5.877,5.877,0,0,0,115,499V469H407v30a5.879,5.879,0,0,0,5.855,6h67.869A6.243,6.243,0,0,0,487,499V469h17a5.913,5.913,0,0,0,6-5.9V371.472A39.417,39.417,0,0,0,473,331.916ZM345.381,100h49.238l17.326,58h-83.89ZM88.675,246H433.328A27.7,27.7,0,0,1,461,273.648v59.3a39.854,39.854,0,0,0-30,38.526V390H91V371.472a39.854,39.854,0,0,0-30-38.526v-59.3A27.7,27.7,0,0,1,88.675,246ZM103,493H47V469h56Zm372,0H419V469h56Zm23-36H24V371.472a27.5,27.5,0,1,1,55,0V395.8a6.448,6.448,0,0,0,6.31,6.2H436.693A6.445,6.445,0,0,0,443,395.8V371.472a27.5,27.5,0,1,1,55,0Z"/>
                                    <path d="M214,203.238V56.626A5.64,5.64,0,0,0,208.062,51h-53.92L112.272,8.944a5.737,5.737,0,0,0-8.484.047L61.919,51H8a5.693,5.693,0,0,0-6,5.626V203.238A5.8,5.8,0,0,0,8,209H208.062A5.749,5.749,0,0,0,214,203.238ZM174,131.8l-42.23,19.686L80.365,116.443a6.245,6.245,0,0,0-7.131.163L41,140.686V87H174ZM41,155.689l36.062-26.911,50.885,34.542a6.17,6.17,0,0,0,3.427,1.039,5.169,5.169,0,0,0,2.332-.557L174,145.025V173H41ZM178.155,75H37.689L23.243,63H192.5ZM29,84.091V176.2L14,189.928V70.473ZM37.7,185H178.14l13.767,12H23.835ZM186,176.21V84.078l16-13.9V190.226ZM108.03,21.859,137.172,51H78.889Z"/>
                                    <path d="M128.374,133.2a20.214,20.214,0,1,0-20.214-20.214A20.236,20.236,0,0,0,128.374,133.2Zm0-28.427a8.214,8.214,0,1,1-8.214,8.213A8.223,8.223,0,0,1,128.374,104.768Z"/>
                                    <path d="M348.787,308.44a22.087,22.087,0,0,0,0,21.662l-8.479,8.479a6,6,0,1,0,8.484,8.485l8.479-8.48a22.09,22.09,0,0,0,21.662,0l8.478,8.479a6,6,0,0,0,8.486-8.485l-8.487-8.488a22.211,22.211,0,0,0,0-21.644l8.487-8.488a6,6,0,0,0-8.486-8.485l-8.478,8.479a22.092,22.092,0,0,0-21.661,0l-8.48-8.479a6,6,0,1,0-8.484,8.485Zm19.316.678a10.156,10.156,0,1,1-7.179,2.974A10.083,10.083,0,0,1,368.1,309.118Z"/>
                                    <path d="M238.787,308.44a22.087,22.087,0,0,0,0,21.662l-8.479,8.479a6,6,0,1,0,8.484,8.485l8.479-8.48a22.09,22.09,0,0,0,21.662,0l8.478,8.479a6,6,0,0,0,8.486-8.485l-8.487-8.488a22.211,22.211,0,0,0,0-21.644l8.487-8.488a6,6,0,0,0-8.486-8.485l-8.478,8.479a22.092,22.092,0,0,0-21.661,0l-8.48-8.479a6,6,0,1,0-8.484,8.485Zm19.316.678a10.156,10.156,0,1,1-7.179,2.974A10.083,10.083,0,0,1,258.1,309.118Z"/>
                                    <path d="M128.787,308.44a22.087,22.087,0,0,0,0,21.662l-8.479,8.479a6,6,0,1,0,8.484,8.485l8.479-8.48a22.09,22.09,0,0,0,21.662,0l8.478,8.479a6,6,0,0,0,8.486-8.485l-8.487-8.488a22.211,22.211,0,0,0,0-21.644l8.487-8.488a6,6,0,0,0-8.486-8.485l-8.478,8.479a22.092,22.092,0,0,0-21.661,0l-8.48-8.479a6,6,0,1,0-8.484,8.485Zm19.316.678a10.156,10.156,0,1,1-7.179,2.974A10.083,10.083,0,0,1,148.1,309.118Z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Всекидневна</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service2Active = true, serviceActive = false, service3Active = false, service4Active = false, service5Active = false, service6Active = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" viewBox="0 0 480 480" width="75" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m472 96h-144c-4.417969 0-8 3.582031-8 8v184h-176v-24c0-4.417969-3.582031-8-8-8h-24v16h16v16h-32v-32h-16v32h-32v-16h16v-16h-24c-4.417969 0-8 3.582031-8 8v24h-24c-4.417969 0-8 3.582031-8 8v176c0 4.417969 3.582031 8 8 8h464c4.417969 0 8-3.582031 8-8v-368c0-4.417969-3.582031-8-8-8zm-136 16h128v96h-128zm-16 224h-144v-32h144zm-304-32h144v32h-144zm0 48h144v112h-144zm160 0h64v112h-64zm80 0h64v112h-64zm80 112v-240h128v240zm0 0"/>
                                    <path d="m352 144h16v32h-16zm0 0"/>
                                    <path d="m352 256h16v32h-16zm0 0"/>
                                    <path d="m48 320c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
                                    <path d="m80 320c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
                                    <path d="m112 320c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
                                    <path d="m144 320c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
                                    <path d="m8 152h160c4.417969 0 8-3.582031 8-8v-24c0-2.121094-.84375-4.15625-2.34375-5.65625l-32-32c-1.5-1.5-3.535156-2.34375-5.65625-2.34375h-8v-72c0-4.417969-3.582031-8-8-8h-64c-4.417969 0-8 3.582031-8 8v72h-8c-2.121094 0-4.15625.84375-5.65625 2.34375l-32 32c-1.5 1.5-2.34375 3.535156-2.34375 5.65625v24c0 4.417969 3.582031 8 8 8zm56-136h48v64h-48zm-48 107.3125 27.3125-27.3125h89.375l27.3125 27.3125v12.6875h-144zm0 0"/>
                                    <path d="m136 368h-96c-4.417969 0-8 3.582031-8 8v64c0 4.417969 3.582031 8 8 8h96c4.417969 0 8-3.582031 8-8v-64c0-4.417969-3.582031-8-8-8zm-8 64h-80v-48h80zm0 0"/>
                                    <path d="m232 312h32v16h-32zm0 0"/>
                                    <path d="m208 392h16v32h-16zm0 0"/>
                                    <path d="m272 392h16v32h-16zm0 0"/>
                                    <path d="m192 200h48c4.417969 0 8-3.582031 8-8v-8c-.054688-14.546875-9.914062-27.226562-24-30.863281v-153.136719h-16v153.136719c-14.085938 3.636719-23.945312 16.316406-24 30.863281v8c0 4.417969 3.582031 8 8 8zm24-32c8.835938 0 16 7.164062 16 16h-32c0-8.835938 7.164062-16 16-16zm0 0"/>
                                    <path d="m248 136h48c4.417969 0 8-3.582031 8-8v-8c-.054688-14.546875-9.914062-27.226562-24-30.863281v-89.136719h-16v89.136719c-14.085938 3.636719-23.945312 16.316406-24 30.863281v8c0 4.417969 3.582031 8 8 8zm24-32c8.835938 0 16 7.164062 16 16h-32c0-8.835938 7.164062-16 16-16zm0 0"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Кухня</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service3Active = true, service2Active = false, serviceActive = false, service4Active = false, service5Active = false, service6Active = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <path d="M485.4,272.067h-8.533v-179.2c0-42.667-34.133-76.8-76.8-76.8c-37.77,0-64.935,28.006-67.974,69.041
                                        c-19.829,3.748-34.426,20.826-34.426,41.892c0,5.12,3.413,8.533,8.533,8.533h68.267c5.12,0,8.533-3.413,8.533-8.533
                                        c0-20.833-14.274-37.769-33.767-41.767c2.55-27.116,18.836-52.1,50.834-52.1c33.28,0,59.733,26.453,59.733,59.733v179.2H202.975
                                        c-3.86-19.681-20.878-34.133-41.841-34.133c-8.533-10.24-20.48-17.067-34.133-17.067c-16.213,0-30.72,9.387-37.547,23.04
                                        c-6.827-3.413-14.507-5.973-22.187-5.973c-20.963,0-37.981,14.452-41.841,34.133H24.6c-14.507,0-25.6,11.093-25.6,25.6
                                        c0,14.507,11.093,25.6,25.6,25.6h10.255l23.025,91.307c6.827,26.453,30.72,45.227,58.027,45.227h6.827l-11.093,22.187
                                        c-2.56,4.267-0.853,9.387,3.413,11.093c0.853,0.853,2.56,0.853,3.413,0.853c3.413,0,5.973-1.707,7.68-4.267L141.08,459.8h236.8
                                        l14.507,29.013c1.707,3.413,4.267,5.12,7.68,5.12c1.707,0,2.56,0,3.413-1.707c3.413-1.707,5.12-6.827,3.413-11.093
                                        l-10.689-21.379c26.419-0.938,49.266-19.39,55.916-44.328l23.24-92.16h10.04c14.507,0,25.6-11.093,25.6-25.6
                                        C511,283.16,499.907,272.067,485.4,272.067z M365.08,118.467h-48.64c3.413-10.24,13.653-17.067,24.747-17.067
                                        S361.667,108.227,365.08,118.467z M67.267,255c7.68,0,14.507,3.413,20.48,9.387c1.707,2.56,5.12,3.413,8.533,2.56
                                        s5.12-3.413,5.973-6.827c2.56-12.8,12.8-22.187,25.6-22.187c9.387,0,17.92,4.267,22.187,12.8c1.707,3.413,5.973,5.12,9.387,4.267
                                        c0.853,0,1.707,0,2.56,0c11.093,0,20.48,6.827,23.893,17.067H43.373C46.787,261.827,56.173,255,67.267,255z M436.76,410.307
                                        c-5.12,18.773-22.187,32.427-41.813,32.427H116.76c-19.627,0-36.693-13.653-41.813-32.427l-22.187-87.04h404.48L436.76,410.307z
                                        M485.4,306.2h-17.067H41.667H24.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h8.533h162.133H485.4
                                        c5.12,0,8.533,3.413,8.533,8.533S490.52,306.2,485.4,306.2z"/>
                                    <path d="M306.2,173.933c5.12,0,8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v4.267
                                        C297.667,170.52,301.08,173.933,306.2,173.933z"/>
                                    <path d="M306.2,213.187c5.12,0,8.533-4.267,8.533-8.533v-9.387c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v9.387
                                        C297.667,209.773,301.08,213.187,306.2,213.187z"/>
                                    <path d="M306.2,246.467c5.12,0,8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v4.267
                                        C297.667,243.053,301.08,246.467,306.2,246.467z"/>
                                    <path d="M340.333,173.933c5.12,0,8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533
                                        c-5.12,0-8.533,3.413-8.533,8.533v4.267C331.8,170.52,335.213,173.933,340.333,173.933z"/>
                                    <path d="M331.8,204.653c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-4.267,8.533-8.533v-9.387c0-5.12-3.413-8.533-8.533-8.533
                                        c-5.12,0-8.533,3.413-8.533,8.533V204.653z"/>
                                    <path d="M331.8,237.933c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533
                                        c-5.12,0-8.533,3.413-8.533,8.533V237.933z"/>
                                    <path d="M374.467,173.933c5.12,0,8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v4.267
                                        C365.933,170.52,369.347,173.933,374.467,173.933z"/>
                                    <path d="M365.933,204.653c0,5.12,3.413,8.533,8.533,8.533S383,208.92,383,204.653v-9.387c0-5.12-3.413-8.533-8.533-8.533
                                        s-8.533,3.413-8.533,8.533V204.653z"/>
                                    <path d="M365.933,237.933c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-4.267c0-5.12-3.413-8.533-8.533-8.533
                                        s-8.533,3.413-8.533,8.533V237.933z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Баня и WC</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service4Active = true, service2Active = false, service3Active = false, serviceActive = false, service5Active = false, service6Active = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                                    <path d="M472,272h-8v-24c-0.021-15.886-9.44-30.254-24-36.608V88c-0.002-3.18-1.886-6.056-4.8-7.328
                                    c3.121-5.002,4.783-10.776,4.8-16.672c0-17.673-14.327-32-32-32c-17.673,0-32,14.327-32,32c0.033,5.634,1.569,11.157,4.448,16
                                    H99.552c2.879-4.843,4.415-10.366,4.448-16c0-17.673-14.327-32-32-32S40,46.327,40,64c0.017,5.896,1.679,11.67,4.8,16.672
                                    C41.886,81.944,40.002,84.82,40,88v123.392C25.44,217.746,16.021,232.114,16,248v24H8c-4.418,0-8,3.582-8,8v112
                                    c0,4.418,3.582,8,8,8h8v40c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-40h352v40c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8
                                    v-40h8c4.418,0,8-3.582,8-8V280C480,275.582,476.418,272,472,272z M408,48c8.837,0,16,7.163,16,16s-7.163,16-16,16
                                    s-16-7.163-16-16S399.163,48,408,48z M72,48c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16S63.163,48,72,48z M56,96h368
                                    v112h-32.208c5.294-6.883,8.179-15.316,8.208-24v-16c-0.026-22.08-17.92-39.974-40-40h-64c-22.08,0.026-39.974,17.92-40,40v16
                                    c0.029,8.684,2.914,17.117,8.208,24h-48.416c5.294-6.883,8.179-15.316,8.208-24v-16c-0.026-22.08-17.92-39.974-40-40h-64
                                    c-22.08,0.026-39.974,17.92-40,40v16c0.029,8.684,2.914,17.117,8.208,24H56V96z M384,168v16c0,13.255-10.745,24-24,24h-64
                                    c-13.255,0-24-10.745-24-24v-16c0-13.255,10.745-24,24-24h64C373.255,144,384,154.745,384,168z M208,168v16
                                    c0,13.255-10.745,24-24,24h-64c-13.255,0-24-10.745-24-24v-16c0-13.255,10.745-24,24-24h64C197.255,144,208,154.745,208,168z
                                    M32,248c0-13.255,10.745-24,24-24h368c13.255,0,24,10.745,24,24v24H32V248z M48,432H32v-32h16V432z M448,432h-16v-32h16V432z
                                    M464,384H16v-96h448V384z"/>
                                    <path d="M72,352H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S76.418,352,72,352z"/>
                                    <path d="M440,352H104c-4.418,0-8,3.582-8,8s3.582,8,8,8h336c4.418,0,8-3.582,8-8S444.418,352,440,352z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Спалня</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service5Active = true, service2Active = false, service3Active = false, service4Active = false, serviceActive = false, service6Active = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <path d="M490.136,459.868h-5.192V285.389c0-4.025-3.262-7.287-7.287-7.287c-4.025,0-7.287,3.262-7.287,7.287v174.479h-24.92V46.78
                                    c0-3.852-2.99-6.997-6.774-7.261c-0.079-0.006-0.158-0.009-0.237-0.012c-0.092-0.003-0.182-0.014-0.275-0.014H73.836
                                    c-0.088,0-0.174,0.01-0.262,0.013c-0.102,0.004-0.204,0.007-0.306,0.015c-0.211,0.016-0.418,0.043-0.623,0.077
                                    c-0.065,0.011-0.13,0.017-0.194,0.03c-0.283,0.054-0.56,0.124-0.83,0.21c-0.018,0.006-0.035,0.014-0.053,0.019
                                    c-0.251,0.082-0.494,0.179-0.732,0.287c-0.064,0.029-0.126,0.06-0.189,0.091c-0.201,0.098-0.397,0.205-0.588,0.321
                                    c-0.039,0.023-0.08,0.04-0.118,0.065c-0.021,0.014-0.04,0.03-0.061,0.044c-0.239,0.155-0.47,0.319-0.688,0.499
                                    c-0.008,0.007-0.015,0.014-0.022,0.02c-0.204,0.171-0.397,0.354-0.581,0.546c-0.058,0.06-0.113,0.124-0.169,0.186
                                    c-0.135,0.15-0.263,0.305-0.385,0.466c-0.049,0.065-0.099,0.128-0.145,0.194c-0.149,0.21-0.288,0.427-0.414,0.653
                                    c-0.008,0.014-0.017,0.027-0.025,0.041c-0.137,0.249-0.257,0.508-0.365,0.774c-0.02,0.05-0.036,0.101-0.055,0.151
                                    c-0.08,0.211-0.151,0.426-0.212,0.647c-0.021,0.075-0.04,0.151-0.058,0.227c-0.05,0.206-0.089,0.415-0.121,0.627
                                    c-0.011,0.076-0.026,0.151-0.035,0.227c-0.033,0.281-0.055,0.566-0.055,0.856v413.088H41.63V285.419
                                    c0-4.025-3.262-7.287-7.287-7.287c-4.025,0-7.287,3.262-7.287,7.287v174.449h-5.192c-8.954,0-16.24,7.286-16.24,16.24v19.652
                                    c0,8.954,7.286,16.24,16.24,16.24h468.271c8.954,0,16.24-7.286,16.24-16.24v-19.652
                                    C506.377,467.154,499.091,459.868,490.136,459.868z M430.851,58.303v401.565h-9.793l-91.652-34.261V106.435L430.851,58.303z
                                    M405.779,54.067l-83.355,39.549c-4.683,2.222-7.592,6.649-7.592,11.553v321.805c0,5.31,3.427,10.024,8.732,12.009l55.873,20.885
                                    h-246.9l55.874-20.886c5.304-1.983,8.731-6.696,8.731-12.008V105.17c0-4.903-2.908-9.33-7.591-11.553l-83.357-39.55H405.779z
                                    M81.122,58.303l101.445,48.132v319.174l-91.652,34.26h-9.793V58.303z M490.137,497.427H21.865c-0.919,0-1.667-0.748-1.667-1.667
                                    v-19.652c0-0.919,0.748-1.667,1.667-1.667h12.478h39.493h18.397H419.74h18.397h0.027h39.494h12.478
                                    c0.919,0,1.667,0.748,1.667,1.667v19.652h0C491.804,496.679,491.056,497.427,490.137,497.427z"/>
                                    <path d="M466.089,0H45.911C35.514,0,27.057,8.458,27.057,18.855v231.478c0,4.025,3.262,7.287,7.287,7.287
                                    c4.025,0,7.287-3.262,7.287-7.287V18.855c0-2.361,1.921-4.282,4.282-4.282h420.178c2.361,0,4.282,1.921,4.282,4.282v231.862
                                    c0,4.025,3.262,7.287,7.287,7.287c4.025,0,7.287-3.262,7.287-7.287V18.855C484.945,8.458,476.487,0,466.089,0z"/>
                                    <path d="M407.125,93.03c-1.372,0-2.74,0.322-3.956,0.929l-50.884,25.453c-2.893,1.446-4.69,4.322-4.69,7.505v122.115
                                    c-0.001,4.704,3.971,8.558,8.851,8.589l50.823,0.329c0.016,0,0.046,0,0.062,0c4.842,0,8.78-3.824,8.78-8.526V101.739
                                    C416.111,96.937,412.081,93.03,407.125,93.03z M401.536,243.34l-39.37-0.254V130.762l39.37-19.692V243.34z"/>
                                    <path d="M407.245,268.248l-50.829,0.511c-4.864,0.052-8.822,3.909-8.822,8.597v128.362c-0.001,3.44,2.102,6.501,5.366,7.803
                                    l50.878,20.212c1.045,0.415,2.148,0.625,3.282,0.625c4.957,0,8.99-3.91,8.99-8.715V276.768
                                    C416.11,272.07,412.175,268.248,407.245,268.248z M401.536,417.137l-39.37-15.64V283.275l39.37-0.397V417.137z"/>
                                    <path d="M155.555,268.759l-50.917-0.511c-4.838,0-8.773,3.822-8.773,8.52v148.875c-0.001,4.805,4.032,8.715,8.989,8.715
                                    c1.134,0,2.237-0.211,3.28-0.625l50.884-20.213c3.258-1.296,5.362-4.359,5.362-7.801V277.356
                                    C164.38,272.668,160.422,268.811,155.555,268.759z M149.807,401.499l-39.37,15.639c0,0,0-134.258,0-134.258l39.37,0.396V401.499z"
                                    />
                                    <path d="M159.693,119.412l-50.888-25.453c-1.215-0.607-2.583-0.929-3.955-0.929c-4.955,0-8.986,3.907-8.986,8.709v147.686
                                    c0,4.701,3.939,8.526,8.779,8.526c0.016,0,0.045,0,0.061,0l50.824-0.329c4.881-0.031,8.852-3.885,8.852-8.59V126.917
                                    C164.38,123.737,162.586,120.862,159.693,119.412z M149.807,243.086l-39.37,0.254c0,0,0-132.27,0-132.27l39.37,19.692V243.086z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Прозорци и дограма</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service6Active = true, service2Active = false, service3Active = false, service4Active = false, service5Active = false, serviceActive = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                    <circle cx="42.667" cy="213.333" r="42.667"/>
                                    <circle cx="213.333" cy="213.333" r="42.667"/>
                                    <circle cx="384" cy="213.333" r="42.667"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">И други</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="results">
                <div class="container" v-show="serviceActive">
                    <p>
                    Това е най-обитаваната зона от дома, поради това там се натрупват най-много замърсявания. Това е причината да обръщаме специално внимание на всеки елемент. Според покритието на повърхностите се използват подходящите почистващи препарати.<br />
                    </p>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>ПРАХОСМУКИРАНЕ</li>
                                    <li>МАШИННО ИЗМИВАНЕ</li>
                                    <li>РЪЧНО ИЗМИВАНЕ</li>
                                    <li>ПОЧИСТВАНЕ</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>НЕ ВКЛЮЧВА</h3>
                                <ul class="one-col">
                                    <li>ОБЕЗПРАШАВАНЕ</li>
                                    <li>ПОЛИРАНЕ</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ПОВЪРХНОСТИ</h3>
                                <ul class="one-col">
                                    <li>ТВЪРДИ ПОДОВИ НАСТИЛКИ</li>
                                    <li>МЕКИ ПОДОВИ НАСТИЛКИ</li>
                                    <li>МЕБЕЛИ</li>
                                    <li>ТЕХНИКА</li>
                                    <li>КЛЮЧОВЕ И КОНТАКТИ</li>
                                    <li>ВРАТИ, БРАВИ И ПРЕГРАДИ</li>
                                    <li>СТЪПАЛА И ПАРАПЕТИ</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>НЕ ВКЛЮЧВА</h3>
                                <ul class="one-col">
                                    <li>ОСВЕТИТЕЛНИ ТЕЛА</li>
                                    <li>КЛИМАТИЦИ</li>
                                    <li>СТЪКЛЕНИ ПРЕГРАДНИ СТЕНИ</li>
                                    <li>МЕБЕЛИ</li>
                                    <li>ЧЕРНА ТЕХНИКА</li>
                                    <li>БЯЛА ТЕХНИКА</li>
                                    <li>ЩОРИ</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service2Active">
                    <p>
                        <strong>Кухнята</strong> е помещение, в което липсата на добра <strong>поддръжка</strong> постепенно води до натрупване на петна, засъхнала мазнина и неприятни миризми. Това е причината на нея да отделяме голямо внимание при почистването и резултатът да е най-забележим.<br />
                    </p>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ПОВЪРХНОСТИ</h3>
                                <ul class="one-col">
                                    <li>КУХНЕНСКИ ПЛОТ</li>
                                    <li>МИВКА</li>
                                    <li>КУХНЕНСКИ УРЕДИ - ВЪНШНО</li>
                                    <li>РАФТОВЕ</li>
                                    <li>ШКАФОВЕ &ndash; ВЪНШНО</li>
                                    <li>КОТЛОНИ</li>
                                    <li>ДРУГИ, СПОРЕД ОБЗАВЕЖДАНЕТО</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>НЕ ВКЛЮЧВА</h3>
                                <ul class="one-col">
                                    <li>АБСОРБЕРИ</li>
                                    <li>ФУРНА (ВЪНШНО И ВЪТРЕШНО)</li>
                                    <li>ХЛАДИЛНИК (ВЪНШНО И ВЪТРЕШНО)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service3Active">
                    <p>
                    Банята и тоалетната са помещения, където се концентрират най-много бактерии и микроби. За да предотвратим развитието им, проявяваме особено внимание при дезинфектиране на всеки елемент от санитарния възел.<br />
                    </p>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ПОВЪРХНОСТИ</h3>
                                <ul class="one-col">
                                    <li>ВАНА</li>
                                    <li>ДУШ КАБИНА</li>
                                    <li>ТОАЛЕТНА ЧИНИЯ</li>
                                    <li>МИВКА</li>
                                    <li>ОГЛЕДАЛА</li>
                                    <li>СТЪКЛЕНИ ПРЕГРАДИ</li>
                                    <li>ЗАВЕСИ</li>
                                    <li>СТЕНИ</li>
                                    <li>ПОД</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>НЕ ВКЛЮЧВА</h3>
                                <ul class="one-col">
                                    <li>ОТСТРАНЯВАНЕ НА КОТЛЕН КАМЪК</li>
                                    <li>ПОЧИСТВАНЕ НА ФУГИ</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service4Active">
                    <p>
                        Спалнята е мястото, където се отпускате след дългия работен ден. Тя трябва да бъде свежа, чиста и уютна, за да бъде почивката пълноценна. Почистването в спалнята обхваща всички нейни елементи - от матраците до бравите на вратите.<br />
                    </p>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>ПРАХОСМУКИРАНЕ</li>
                                    <li>МАШИННО ИЗМИВАНЕ</li>
                                    <li>ПОЧИСТВАНЕ</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ПОВЪРХНОСТИ</h3>
                                <ul class="one-col">
                                    <li>матраци</li>
                                    <li>гардероби - външно и вътрешно</li>
                                    <li>твърди подови настилки</li>
                                    <li>меки подови настилки</li>
                                    <li>мебели</li>
                                    <li>ключове и контакти</li>
                                    <li>врати, брави и прегради</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>НЕ ВКЛЮЧВА</h3>
                                <ul class="one-col">
                                    <li>обезпрашаване</li>
                                    <li>полиране</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="service5Active">
                    <p>
                        За да се създаде уют в дома, прозорците и дограмата трябва да бъдат перфектно и професионално почистени. По тях,&nbsp;отвън и отвътре,&nbsp;полепва фина прах, която се чисти трудно с обикновен препарат. Дори след почистване в порите на повърхностите остава запечатана прах. Затова ние използваме препарат, който разгражда фината прах и по този начин възвръщаме първоначалния блясък на стъклото. Почистването се извършва вътрешно и външно.
                    </p>
                </div>
                <div class="container" v-show="service6Active">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>Дейности</h3>
                                <ul class="one-col">
                                    <li>Почистване на тераси, первази и воронки. Освобождаване на отводнителни отвори от листа и други.</li>
                                    <li>Почистване на врати, брави, ключове и контакти с подходящи препарати и дезинфектанти.</li>
                                    <li>Почистване на праха от стени и тавани. Почистване на паяжини и прах от гипсови корнизи и орнаменти.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="add-services flex flex-row mt-3">
                                <h3>Допълнителни услуги</h3>
                                <ul class="one-col">
                                    <li>почистване на тераси</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ServiceForm :servicePrice="3"/>
    </div>
</template>
<script>
import ServiceForm from '../../../ServiceForm'

export default {
    name: "HomesStandard",
    data() {
        return {
            serviceActive: false,
            service2Active: false,
            service3Active: false,
            service4Active: false,
            service5Active: false,
            service6Active: false,
        }
    },
    components: {
        ServiceForm,
    }
}
</script>